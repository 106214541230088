import CandidateSvg from './candidate.svg'
import CustomizeSvg from './customize.svg'
import DashboardsSvg from './dashboards.svg'
import FilteringSvg from './filtering.svg'
import MatchesSvg from './matches.svg'
import PastSvg from './past.svg'
import ProfileSvg from './profile.svg'
import SchedulingSvg from './scheduling.svg'
import ScoreSvg from './score.svg'
import ToolsSvg from './tools.svg'
import TrackingSvg from './tracking.svg'
import TickSvg from './tick.svg'
import TransparencySvg from './transparency.svg'
import Image from 'next/image'


export const CandidateIcon = () => (
  <Image
    alt="Candidate"
    width={40}
    height={52}
    src={CandidateSvg} />
)

export const CustomizeIcon = () => (
  <Image
    alt="Customize"
    width={60}
    height={60}
    src={CustomizeSvg} />
)

export const DashboardsIcon = () => (
  <Image
    alt="Dashboards"
    width={60}
    height={60}
    src={DashboardsSvg} />
)

export const FilteringIcon = () => (
  <Image
    alt="Filtering"
    width={60}
    height={60}
    src={FilteringSvg} />
)

export const MatchesIcon = () => (
  <Image
    alt="Matches"
    width={52}
    height={52}
    src={MatchesSvg} />
)

export const PastIcon = () => (
  <Image
    alt="Past"
    width={60}
    height={60}
    src={PastSvg} />
)

export const ProfileIcon = () => (
  <Image
    alt="Profile"
    width={40}
    height={52}
    src={ProfileSvg} />
)

export const SchedulingIcon = () => (
  <Image
    alt="Scheduling"
    width={60}
    height={60}
    src={SchedulingSvg} />
)

export const ScoreIcon = () => (
  <Image
    alt="Score"
    width={60}
    height={60}
    src={ScoreSvg} />
)

export const ToolsIcon = () => (
  <Image
    alt="Tools"
    width={60}
    height={60}
    src={ToolsSvg} />
)

export const TrackingIcon = () => (
  <Image
    alt="Tracking"
    width={60}
    height={62}
    src={TrackingSvg} />
)

export const TransparencyIcon = () => (
  <Image
    alt="Transparency"
    width={50}
    height={50}
    src={TransparencySvg} />
)

export const TickIcon = () => (
  <Image
    alt="Tick"
    width={25}
    height={24}
    src={TickSvg} />
)
