"use client";

import {
  BriefcaseIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { FormEventHandler, useState } from "react";
import { PlacesReturn } from "~/queries/fetchPlacesAutoComplete";
import { Button } from "../Button";
import { Input } from "../Input";
import LocationField from "../LocationField";
import { SelectField } from "../SelectField";
import LadderWithDecorations from "./LadderWithDecorations";

const Hero: React.FC = function Hero() {
  const router = useRouter();
  const [query, setQuery] = useState<string>("");
  const [jobType, setJobType] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [placeId, setPlaceId] = useState<string>("");
  const handleLocationChange = (place: PlacesReturn) => {
    setLocation(place.description);
    setPlaceId(place.place_id);
  };
  const handleSearch: FormEventHandler = e => {
    e.preventDefault();
    const newQuery = new URLSearchParams();

    newQuery.set("search", query);
    newQuery.set("place_id", placeId);
    newQuery.set("position", jobType);

    const url = `/jobs?${newQuery.toString()}`;

    router.push(url);
  };

  return (
    <div className="w-full bg-[#F8FAFF] p-10 lg:p-[80px]">
      <div className="mx-auto flex max-w-[1200px] flex-col items-center justify-between bg-[#F8FAFF] xl:flex-row">
        <div className="flex flex-col align-middle">
          <h1 className="text-center text-[35px] font-bold text-[#1F2D5C] lg:text-left lg:text-[60px]">
            Hiring shouldn&apos;t suck
          </h1>
          <p className="py-[50px] text-center text-[16px] font-semibold text-[#1C2024] lg:text-left lg:text-[20px]">
            SCALIS matches qualified candidates to relevant companies
            <br />
            with the first ever fully-integrated ATS & job board leveraging AI
          </p>
          <div className="mx-auto flex gap-5 lg:mx-0">
            <Button asChild variant="outline" className="h-10 text-center">
              <Link href="/signup?job_seeker">For Job Seekers</Link>
            </Button>
            <Button asChild className="h-10 text-center">
              <Link href="/signup?employer">For Employers</Link>
            </Button>
          </div>
        </div>
        <div className="h-[40px]"></div>
        <LadderWithDecorations />
      </div>
      <form
        onSubmit={handleSearch}
        className="mx-auto mt-[80px] flex max-w-[1100px] flex-col items-stretch justify-stretch gap-5 rounded-xl border border-[#D9E2FC] px-5 py-8 md:flex-row md:px-10 md:py-5"
      >
        <div className="relative flex flex-[250] items-center">
          <span className="absolute left-0 top-0 z-40 flex h-full items-center px-3">
            <MagnifyingGlassIcon className="z-40 h-5 w-5 text-[#000D3D]" />
          </span>
          <Input
            placeholder="Job Title"
            className="z-30 h-10 w-full rounded-lg pl-9"
            value={query}
            onChange={e => setQuery(e.currentTarget.value)}
          />
        </div>
        <div className="relative flex flex-[175] items-center">
          <LocationField
            name="location"
            label=""
            value={location}
            setValue={handleLocationChange}
            className="h-10 w-full rounded-lg border-[#D8DBDF] pl-9 "
            placeholder="Location"
            startAdornment={<MapPinIcon className="h-5 w-5" />}
          />
        </div>
        <div className="relative flex flex-[175] items-center">
          <SelectField
            emptyTerm="Job Type"
            name="job_type"
            placeholder="Job Type"
            searchPlaceholder="Job Type"
            label=""
            startAdornment={<BriefcaseIcon className="h-5 w-5 text-[000D3D]" />}
            options={[
              { label: "Full Time", value: "FULL_TIME" },
              { label: "Part Time", value: "PART_TIME" },
              { label: "Seasonal", value: "SEASONAL" },
              { label: "Contractor", value: "CONTRACTOR" },
              { label: "Internship", value: "INTERNSHIP" },
              { label: "Per-Diem", value: "PER_DIEM" },
              { label: "Temporary", value: "TEMPORARY" },
              { label: "Other", value: "OTHER" },
            ]}
            multi={false}
            value={jobType}
            setValue={(_, value) => setJobType(value)}
            className="h-10 w-full rounded-lg border-[#D8DBDF] pl-9"
            containerClassName="w-full"
          />
        </div>
        <Button
          size="icon"
          className="hidden h-9 min-h-9 w-9 min-w-9 items-center rounded-full md:flex"
        >
          <MagnifyingGlassIcon className="h-5 w-5 text-white" />
        </Button>
        <Button size="icon" className="w-full md:hidden">
          <MagnifyingGlassIcon className="h-5 w-5 text-white" /> Search
        </Button>
      </form>
    </div>
  );
};

export default Hero;
