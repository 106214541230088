"use client";

import { useState } from "react";
import { cn } from "~/utils/cn";
import { TickIcon } from "../Features/Icons";
import { Button } from "../../Button";
import { useRouter } from "next/navigation";

const pricing = {
  monthly: {
    basePlan: 29.99,
    addOnSeat: 7.99,
  },
  yearly: {
    basePlan: 323.99,
    addOnSeat: 86.99,
  },
} as const;

type PayType = keyof typeof pricing;

export default function Pricing() {
  const router = useRouter();
  const [currentType, setCurrentType] = useState<PayType>("monthly");
  const onSelectTab = (type: PayType) => () => setCurrentType(type);
  const onChooseBasePlan = () => router.push("/");
  return (
    <section className="mt-10 flex flex-col gap-12 px-6">
      <div className="flex justify-center">
        <Tab
          onClick={onSelectTab("monthly")}
          selected={currentType === "monthly"}
          label="Pay Monthly"
        />
        <Tab
          onClick={onSelectTab("yearly")}
          selected={currentType === "yearly"}
          label="Pay Yearly"
        />
      </div>
      <div className="mx-auto flex max-w-5xl flex-col items-center">
        <div className="flex w-full flex-col gap-10 rounded-lg border border-[#8DA4EF] px-4 py-[3.75rem] md:p-[3.75rem] lg:flex-row">
          <div className="m-auto p-4 pt-5 md:p-10">
            <div className="flex flex-col gap-4">
              <h1 className="text-center text-[28px] font-bold leading-[36px] text-[#0020D3]">
                Base Plan
              </h1>
              <h2 className="text-center text-[35px] font-extrabold leading-[40px] text-[#1C2024]">
                $ {pricing[currentType].basePlan}
              </h2>
              <p className="text-center text-base font-normal leading-normal text-[#595959]">
                {currentType === "monthly" ? "per month" : "per year"}
              </p>
              <div className="mx-auto w-full border" />
            </div>
            <div className="mt-10 flex flex-col gap-10">
              <ul className="space-y-5">
                <LI text="All ATS features included" />
                <LI text="No add ons for additional tools" />
                <LI text="No hidden fees" />
                <LI text="One user seat included" />
              </ul>
              <div className="flex justify-center">
                <Button
                  className="mx-auto"
                  size="lg"
                  onClick={onChooseBasePlan}
                >
                  Choose Base Plan
                </Button>
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-col rounded-lg border border-[#8DA4EF] p-4 pt-5 md:p-10">
            <div className="flex flex-col gap-4">
              <h1 className="text-center text-[28px] font-bold leading-[36px] text-[#8DA4EF]">
                Add-On HR Seats
              </h1>
              <h2 className="text-center text-[35px] font-extrabold leading-[40px] text-[#1C2024]">
                $ {pricing[currentType].addOnSeat}
              </h2>
              <p className="text-center text-base font-normal leading-normal text-[#595959]">
                {currentType === "monthly"
                  ? "per user / month"
                  : "per user / year"}
              </p>
              <div className="mx-auto w-full border" />
            </div>
            <div className="mt-10 flex h-full flex-col justify-between gap-10">
              <ul className="space-y-5 ">
                <LI text="One seat per team member" />
                <LI text="Assign user permissions" />
                <LI text="Collaborate and communicate" />
              </ul>
              <div className="flex justify-center">
                <Button
                  className="mx-auto"
                  size="lg"
                  onClick={onChooseBasePlan}
                >
                  Choose Base Plan
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const LI: React.FC<{ text: string }> = ({ text }) => (
  <li className="flex gap-[10px] text-[16px] text-black">
    <TickIcon />
    {text}
  </li>
);

type TabProps = {
  label: string;
  selected: boolean;
  onClick: () => void;
};

const Tab: React.FC<TabProps> = function TypeTab({ label, selected, onClick }) {
  return (
    <span
      onClick={onClick}
      className={cn(
        "cursor-pointer border-b border-b-[#BFC4D1] px-[12px] py-[8px] text-[14px] text-[#BFC4D1] hover:border-[#003D94] hover:text-[#003D94]",
        {
          "border-b-2 border-[#003D94] font-bold text-[#003D94]": selected,
        },
      )}
    >
      {label}
    </span>
  );
};
