"use client";

import { PropsWithChildren, useState } from "react";
import { cn } from "~/utils/cn";
import {
  CandidateIcon,
  CustomizeIcon,
  DashboardsIcon,
  FilteringIcon,
  MatchesIcon,
  PastIcon,
  ProfileIcon,
  SchedulingIcon,
  ScoreIcon,
  ToolsIcon,
  TrackingIcon,
  TransparencyIcon,
} from "./Icons";
import { CompanyDevices, JobseekerDevices } from "./Images";
import CompanyHero from "../CompanyHero";
import JobSeekerHero from "../JobSeekerHero";
import { FeatureCard } from "./FeatureCard";

type HomeType = "jobseeker" | "company";

const Features: React.FC = function Features() {
  const [currentType, setCurrentType] = useState<HomeType>("company");
  const onClick = (type: HomeType) => () => setCurrentType(type);
  return (
    <section className="bg-[#F8FAFF] pt-[60px]">
      <div className="mx-auto flex max-w-[1200px] flex-col items-center">
        <div className="flex">
          <TypeTab
            onClick={onClick("company")}
            selected={currentType === "company"}
            label="For Employers"
          />
          <TypeTab
            onClick={onClick("jobseeker")}
            selected={currentType === "jobseeker"}
            label="For Job Seekers"
          />
        </div>
        <div className="h-[40px]"></div>
        {currentType === "company" && <CompanyHero />}
        {currentType === "jobseeker" && <JobSeekerHero />}
        <div className="h-[40px]"></div>
        <div
          className={cn("grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6", {
            hidden: currentType !== "jobseeker",
          })}
        >
          <FeatureBox>
            <ProfileIcon />
            <FeatureBoxLabel>
              One Profile for
              <br /> Every Application
            </FeatureBoxLabel>
          </FeatureBox>
          <FeatureBox>
            <DashboardsIcon />
            <FeatureBoxLabel>
              Individual Application
              <br /> Dashboards
            </FeatureBoxLabel>
          </FeatureBox>
          <FeatureBox>
            <MatchesIcon />
            <FeatureBoxLabel>
              Job Matches and
              <br /> Recommendations
            </FeatureBoxLabel>
          </FeatureBox>
          <FeatureBox>
            <TransparencyIcon />
            <FeatureBoxLabel>
              Transparency into
              <br />
              the Hiring Process
            </FeatureBoxLabel>
          </FeatureBox>
          <FeatureBox>
            <TrackingIcon />
            <FeatureBoxLabel>
              Track All
              <br />
              Your Applications
            </FeatureBoxLabel>
          </FeatureBox>
          <FeatureBox>
            <CandidateIcon />
            <FeatureBoxLabel>
              Detailed
              <br /> Candidate Profile
            </FeatureBoxLabel>
          </FeatureBox>
        </div>
        <div
          className={cn("grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6", {
            hidden: currentType !== "company",
          })}
        >
          <FeatureBox>
            <FilteringIcon />
            <FeatureBoxLabel>
              Advanced
              <br /> Candidate Filtering
            </FeatureBoxLabel>
          </FeatureBox>
          <FeatureBox>
            <SchedulingIcon />
            <FeatureBoxLabel>
              Automated
              <br /> Interview Scheduling
            </FeatureBoxLabel>
          </FeatureBox>
          <FeatureBox>
            <ScoreIcon />
            <FeatureBoxLabel>
              Candidate
              <br /> Match Score
            </FeatureBoxLabel>
          </FeatureBox>
          <FeatureBox>
            <PastIcon />
            <FeatureBoxLabel>
              Dynamic Past
              <br /> Applicant Resumes
            </FeatureBoxLabel>
          </FeatureBox>
          <FeatureBox>
            <CustomizeIcon />
            <FeatureBoxLabel>
              Customizable
              <br /> Hiring Pipeline
            </FeatureBoxLabel>
          </FeatureBox>
          <FeatureBox>
            <ToolsIcon />
            <FeatureBoxLabel>
              Team
              <br /> Collaboration Tools
            </FeatureBoxLabel>
          </FeatureBox>
        </div>
        <div className="max-w-[380px] py-[80px] md:max-w-[660px] lg:max-w-[900px]">
          {currentType === "company" && <CompanyDevices />}
          {currentType === "jobseeker" && <JobseekerDevices />}
        </div>
        <div
          className={cn(
            "mx-[40px] grid max-w-[1200px] grid-cols-1 gap-[40px] md:grid-cols-2 lg:grid-cols-3",
            {
              hidden: currentType !== "jobseeker",
            },
          )}
        >
          <FeatureCard
            title="One Profile for Every Application"
            description="Fill out your profile once and use it over and over again to apply to every employer using SCALIS in just a few clicks!"
            icon={<ProfileIcon />}
            features={[
              "Universal application profile",
              "Quickly apply to jobs",
              "No complicated processes",
              "Keep employers up-to-date",
            ]}
          />
          <FeatureCard
            title="Individual Application Dashboards"
            icon={<DashboardsIcon />}
            description="Track your progress, see the employers hiring process, take notes, and share documents with application dashboards"
            features={[
              "Schedule interviews",
              "Take notes on job processes",
              "Receive and send documents",
              "Easy access to the job description",
            ]}
          />
          <FeatureCard
            title="Job Matches and Recommendations"
            icon={<MatchesIcon />}
            description="Get job recommendations based on your profile from our AI-based algorithm so you can find the best jobs"
            features={[
              "Receive qualified recommendations",
              "Be invited to apply by employers",
              "Find the best opportunities",
            ]}
          />
          <FeatureCard
            title="Transparency into the Hiring Process"
            icon={<TransparencyIcon />}
            description="See how many rounds are in each job’s hiring process and how many tasks remain"
            features={[
              "See each jobs hiring funnel",
              "See what stage you are in",
              "Understand key requirements",
            ]}
          />
          <FeatureCard
            title="Track All Your Applications"
            description="Customize your entire hiring pipeline from start to finish to create the ideal workflow for your hiring team"
            icon={<TrackingIcon />}
            features={[
              "Sort jobs by status",
              "See past opportunities",
              "View past notes",
              "Learn from each application",
            ]}
          />
          <FeatureCard
            title="Detailed Candidate Profile"
            description="Create an in-depth and detailed candidate profile that allows you to showcase your talents to prospective employers"
            icon={<CandidateIcon />}
            features={[
              "Structured applications profiles",
              "List experiences, skills, and more",
              "No PDF resumes",
              "No risk of being passed over",
            ]}
          />
        </div>
        <div
          className={cn(
            "mx-[40px] grid max-w-[1200px] grid-cols-1 gap-[40px] md:grid-cols-2 lg:grid-cols-3",
            {
              hidden: currentType !== "company",
            },
          )}
        >
          <FeatureCard
            title="Advanced Candidate Filtering"
            icon={<FilteringIcon />}
            description="Sort through candidates with dozens of different filters including demographics, education, skills and more"
            features={[
              "20+ Unique Filters",
              "Narrow candidates down quickly",
              "Reduce screening time",
              "Spot top talent easily",
            ]}
          />
          <FeatureCard
            title="Automated Interview Scheduling"
            icon={<SchedulingIcon />}
            description="Schedule candidate interviews seamlessly with multiple team members across any timezone"
            features={[
              "Send scheduler links",
              "Reduce back-and-forth",
              "Save valuable recruiting time",
              "Involve multiple stakeholders",
            ]}
          />
          <FeatureCard
            title="Candidate Match Score"
            icon={<ScoreIcon />}
            description="Get to the bottom of your applicant stack quickly by ranking candidates with our AI-based Candidate Match Score"
            features={[
              "Find the top talent quickly",
              "Sort candidates easily",
              "Reduce screening time",
            ]}
          />
          <FeatureCard
            title="Dynamic Past Applicant Resume"
            icon={<PastIcon />}
            description="When your past applicants update their resume to apply to any SCALIS employer job, you will get an updated copy"
            features={[
              "Keep ATS data fresh",
              "Build a dynamic talent pool",
              "Tap into past applicants easily",
              "Reduce time to hire",
            ]}
          />
          <FeatureCard
            title="Customizable Hiring Pipeline"
            icon={<CustomizeIcon />}
            description="Customize your entire hiring pipeline from start to finish to create the ideal workflow for your hiring team"
            features={[
              "Control your hiring process",
              "Build standardized pipelines",
              "Generate clean hiring data",
              "Increase hiring efficiency",
            ]}
          />
          <FeatureCard
            title="Team Collaboration Tools"
            icon={<ToolsIcon />}
            description="Note taking, task creation, and other tools allow you to communicate and grow as a team"
            features={[
              "Detailed candidate profiles",
              "Take notes, create tasks",
              "Coordinate with other stakeholders",
              "Build processes collaboratively",
            ]}
          />
        </div>
      </div>
      <div className="h-[40px]"></div>
    </section>
  );
};

const FeatureBox: React.FC<PropsWithChildren> = function FeatureBox({
  children,
}) {
  return (
    <div className="flex h-[124px] w-[200px] flex-col items-center ">
      {children}
    </div>
  );
};

const FeatureBoxLabel: React.FC<PropsWithChildren> = function FeatureBox({
  children,
}) {
  return (
    <p className="mt-[17px] text-center text-[14px] font-bold text-[#1C2024]">
      {children}
    </p>
  );
};

type TypeTabProps = {
  label: string;
  selected: boolean;
  onClick: () => void;
};

const TypeTab: React.FC<TypeTabProps> = function TypeTab({
  label,
  selected,
  onClick,
}) {
  return (
    <span
      onClick={onClick}
      className={cn(
        "cursor-pointer border-b border-b-[#BFC4D1] px-[12px] py-[8px] text-[14px] text-[#BFC4D1] hover:border-[#003D94] hover:text-[#003D94]",
        {
          "border-b-2 border-[#003D94] font-bold text-[#003D94]": selected,
        },
      )}
    >
      {label}
    </span>
  );
};

export default Features;
