"use client";

import "swiper/css";
import Image from "next/image";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

export default function Customers() {
  return (
    <div className="mx-auto flex max-w-[1200px] flex-col items-center py-[60px]">
      <p className="mx-[10px] pb-[40px] text-center text-[20px] font-bold">
        Some of our customers
        <span className="text-[20px] text-[#0020D3]"> hiring better </span>
        and
        <span className="text-[20px] text-[#0020D3]"> easier </span>
        with Scalis
      </p>
      <div className="container [&_.swiper-wrapper]:ease-linear">
        <div className="relative">
          <Swiper
            className="h-[80px]"
            slidesPerView={2}
            allowTouchMove={false}
            spaceBetween={40}
            loop
            speed={5000}
            autoplay={{
              delay: 0,
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
              768: { slidesPerView: 3, spaceBetween: 40 },
              1024: { slidesPerView: 5, spaceBetween: 60 },
            }}
          >
            <SwiperSlide>
              <Image
                fill
                alt="Winc"
                src="/images/pages/landing-page/winc-logo.svg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                fill
                alt="Aument.io"
                src="/images/pages/landing-page/aument-logo.svg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                fill
                alt="Superfiliate"
                src="/images/pages/landing-page/superfiliate-logo.svg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                fill
                alt="Prive Creative"
                src="/images/pages/landing-page/prive-logo.svg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                fill
                alt="Smart Theme"
                src="/images/pages/landing-page/smart-theme-logo.svg"
              />
            </SwiperSlide>
            {/* REPEAT THE LOGOS TO ENSURE IT IS BIG ENOUGH TO SCROLL */}
            <SwiperSlide>
              <Image
                fill
                alt="Winc"
                src="/images/pages/landing-page/winc-logo.svg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                fill
                alt="Aument.io"
                src="/images/pages/landing-page/aument-logo.svg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                fill
                alt="Superfiliate"
                src="/images/pages/landing-page/superfiliate-logo.svg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                fill
                alt="Prive Creative"
                src="/images/pages/landing-page/prive-logo.svg"
              />
            </SwiperSlide>
            <SwiperSlide>
              <Image
                fill
                alt="Smart Theme"
                src="/images/pages/landing-page/smart-theme-logo.svg"
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
