import { ReactNode } from "react"
import { TickIcon } from "./Icons"

type FeatureCardProps = {
  title: string
  description?: string
  features: string[]
  icon?: ReactNode
}

export const FeatureCard: React.FC<FeatureCardProps> = function FeatureCard({ description, title, features, icon }) {

  return (
    <div className="bg-white w-full h-full p-[20px] rounded-[20px] border border-[#8DA4EF]">
      <div className="flex gap-[20px] h-[3.75rem]">
        {icon}
        <h1 className="m-auto text-[16px] font-bold text-[1C2024]">{title}</h1>
      </div>
      <div className="w-full border border-stone-300 mx-auto my-[30px]" />
      <p className="text-base font-normal leading-normal text-[#595959] m-[20px]">
        {description}
      </p>
      <ul className="space-y-[20px]">
        {features.map(f => (
          <li
            key={f.replaceAll(" ", "").toLowerCase()}
            className="flex gap-[10px] text-[16px] text-black"
          >
            <TickIcon />
            {f}
          </li>
        ))}
      </ul>
    </div>
  )
}
