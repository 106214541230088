
export default function CompanyHero() {
  return (
    <section className="bg-[#F8FAFF]">
      <div className="max-w-[1200px] mx-auto flex flex-col items-center">
        <h1 className="text-[48px] font-bold">Looking to Hire?</h1>
        <div className="h-[16px]"></div>
        <p className="text-[20px] font-semibold mx-[10px] text-center">
          Introducing the
          <span className="text-[#0020D3] text-[20px]"> most affordable and comprehensive employers ATS </span>
          to date</p>
      </div>
    </section>
  )
}

