import CompanyDevicesPng from './company-devices.png'
import JobseekerDevicesPng from './jobseeker-devices.png'
import Image from 'next/image'

export const CompanyDevices = () => (
  <Image
    alt="Company Devices"
    src={CompanyDevicesPng} />
)


export const JobseekerDevices = () => (
  <Image
    alt="Job Seeker Devices"
    src={JobseekerDevicesPng} />
)
