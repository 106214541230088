import Image from 'next/image'
import LadderWithDecorationsSvg from './LadderWithDecorations.svg'

export default function LadderWithDecorations() {
  return (
    <Image
      alt="Ladder With Decorations"
      width={376}
      height={360}
      src={LadderWithDecorationsSvg} />
  )
}